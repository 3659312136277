<template>
  <el-table-column
    :label="columnConfig.label"
    :align="columnConfig.align"
  >
    <template slot="header">
      <div class="medium-fact-column">
        <div>{{ columnConfig.label }}</div>
      </div>
    </template>


    <el-table-column
      v-for="(card, cardId) in cards"
      :key="cardId"
      :width="200"
      align="center"
      :label="cardId"
    >
      <template slot="header" slot-scope="scope">
        {{ card.user.full_name }}
        <el-divider class="mini"></el-divider>

        <el-tooltip content="Удалить из матрицы" placement="top" :open-delay="500">
          <el-button
            class="table-action-button"
            type="default"
            round
            size="mini"
            icon="fas fa-user-times"
            @click="$emit('remove-card', card.id)"
          >
          </el-button>
        </el-tooltip>
        <el-tooltip content="Открыть карту" placement="top" :open-delay="500">
          <el-button
            class="table-action-button"
            type="default"
            round
            size="mini"
            icon="fas fa-external-link-alt"
            @click="openCardInNewWindow(card.id)"
          >
          </el-button>
        </el-tooltip>

        <el-tooltip
          v-if="$can(card, 'card.agree')"
          content="Согласовать"
          placement="top"
          :open-delay="500"
        >
          <span style="display:inline-block; margin-left: 15px">
            <el-button
              class="table-action-button"
              :disabled="!$rulesPass(card, 'card.agree')"
              type="success"
              round
              size="mini"
              icon="fas fa-check"
              @click="agreeNegotiation(card)"
            >
            </el-button>
          </span>
        </el-tooltip>


      </template>
      <template v-slot="scope">
        <click-to-edit
          v-model="scope.row.weight[card.id]"
          placeholder=""
          digits-only
          @input="saveElementPropChange(scope.row, 'weight')"
        ></click-to-edit>
      </template>
    </el-table-column>
  </el-table-column>
</template>

<script>

import ClickToEdit from "@/components/ClickToEdit";
import {ElementPropTableColumn} from "@/mixins/elementPropTableColumn";

export default {
  name: 'matrix-weight-table-column',
  components: {ClickToEdit},
  mixins: [ElementPropTableColumn],

  props: {
    isMatrix: {type: Boolean, default: true},
    columnConfig: {type: Object, required: true},
    cards: {},
  },
  data() {
    return {}
  },
  computed: {},
  methods: {
    openCardInNewWindow(cardId){
      let route = this.$router.resolve({ name: 'Card', params: { id: cardId } });
      window.open(route.href);
    },

    agreeNegotiation(card){
      this.$api.card.agreeCard({
        card_id: card.id
      })
        .then((data) => {
          this.$notify.success({
            title: 'Сохранено',
            message: 'Этап успешно сохранен'
          });
        })
    },
  }
}

</script>

<style>
.el-divider.el-divider--horizontal.mini{
  margin: 5px 0;
}
</style>
